import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-17ea0820")
const _hoisted_1 = { class: "not-found" }
const _hoisted_2 = { class: "center" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("dig-ui-title", _hoisted_2, _toDisplayString(_ctx.$t('pageNotFound')), 1)
  ]))
}