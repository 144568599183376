import dayjs, { Dayjs } from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import 'dayjs/locale/fr'
import 'dayjs/locale/en'
import countdown from 'countdown'
import { saveAs } from 'file-saver'

countdown.setLabels(
  ' milliseconde| seconde| minute| heure| jour| semaine| mois| année| décennie| siècle| millénaire',
  ' millisecondes| secondes| minutes| heures| jours| semaines| mois| années| décennies| siècles| millénaires',
  ' et ',
  ', ',
  'maintenant',
)

dayjs.extend(localizedFormat)

export const formatDatetime = (datetime: string): string => {
  return dayjs(datetime).format('DD/MM/YYYY à HH:mm:ss')
}

export const dateToCountdown = (datetime: string): string => {
  return countdown(dayjs(datetime).toDate(), null, undefined, 1).toString()
}

export const timestampToCountdown = (timestamp: number): string => {
  return countdown(dayjs(timestamp).toDate(), null, undefined, 1).toString()
}

export const downloadFile = (base64: string, filename: string) => {
  saveAs(base64, filename)
}

export const getUserOS = (): string => {
  let OSName = 'Unknown OS'
  if (navigator.userAgent.indexOf('Win') !== -1) OSName = 'Windows'
  if (navigator.userAgent.indexOf('Mac') !== -1) OSName = 'Macintosh'
  if (navigator.userAgent.indexOf('Linux') !== -1) OSName = 'Linux'
  if (navigator.userAgent.indexOf('Android') !== -1) OSName = 'Android'
  if (navigator.userAgent.indexOf('like Mac') !== -1) OSName = 'iOS'
  return OSName
}

export const getUserBrowser = (): string => {
  const nAgt = navigator.userAgent
  let browserName, verOffset, nameOffset
  if ((verOffset = nAgt.indexOf('Opera')) != -1) {
    browserName = 'Opera'
  } else if ((verOffset = nAgt.indexOf('MSIE')) != -1) {
    browserName = 'Internet Explorer'
  } else if ((verOffset = nAgt.indexOf('Chrome')) != -1) {
    browserName = 'Chrome'
  } else if ((verOffset = nAgt.indexOf('Safari')) != -1) {
    browserName = 'Safari'
  } else if ((verOffset = nAgt.indexOf('Firefox')) != -1) {
    browserName = 'Firefox'
  } else if (
    (nameOffset = nAgt.lastIndexOf(' ') + 1) <
    (verOffset = nAgt.lastIndexOf('/'))
  ) {
    browserName = nAgt.substring(nameOffset, verOffset)
  }
  return browserName || 'Unknown Browser'
}

export class Utils {
  get locale(): string {
    return dayjs.locale()
  }

  set locale(lang: string) {
    dayjs.locale(lang)
  }

  constructor() {
    this.locale = 'fr'
  }

  toFirstUpperCase(text: string) {
    if (typeof text !== 'string') return ''
    return text.charAt(0).toUpperCase() + text.slice(1)
  }

  UTCToMoment(date: string | undefined): Dayjs {
    if (!date) return dayjs()
    return dayjs(date)
  }

  nowToMoment(): Dayjs {
    return dayjs()
  }

  momentToUTC(dayjs: Dayjs): string {
    return dayjs.toISOString()
  }

  nowToUTC(): string {
    return dayjs().toISOString()
  }

  HTMLToServer(date: string | undefined | null): string {
    if (!date) return ''
    return dayjs(date).format('YYYY/MM/DD')
  }

  UTCToHuman(date: string | undefined | null): string {
    if (!date) return ''
    return dayjs(date).format('MMMM YYYY')
  }

  UTCToYear(date: string | undefined | null): string {
    if (!date) return ''
    return dayjs(date).format('YYYY')
  }

  UTCToMonth(date: string | undefined | null): string {
    if (!date) return ''
    return dayjs(date).format('MMMM')
  }

  UTCToDay(date: string | undefined | null): string {
    if (!date) return ''
    return dayjs(date).format('DDDD')
  }

  UTCToShort(date: string | undefined | null): string {
    if (!date) return ''
    return dayjs(date).format('L')
  }
}

export const utils = new Utils()
