
import { defineComponent, SetupContext, computed } from 'vue'
import { USER_ROLES } from '@/model/User'
import { StoreGettersAuth, storeAuth } from '@/store/auth'

export default defineComponent({
  name: `BaseReportHandler`,
  components: {},
  props: {
    report: {
      type: String,
      required: false,
      default: '',
    },
  },
  emits: ['delete', 'load', 'download'],
  setup(
    props: Readonly<{
      report: string
    }>,
    context: SetupContext,
  ) {
    const isSuperadmin = computed(
      () => storeAuth.getters[StoreGettersAuth.ROLE] === USER_ROLES.SUPERADMIN,
    )

    return { isSuperadmin }
  },
})
