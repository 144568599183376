
import { defineComponent, SetupContext, ref, Ref } from 'vue'
import { storeLocale, StoreActionsLocale } from '@/store/locales'
import { DigUiPopup } from '@digithia/ui/popup'

export interface Country {
  lang: string
  flag: string
  name: string
}

export default defineComponent({
  name: `BaseLocaleSelect`,
  components: {},
  props: {},
  emits: ['validate', 'cancel'],
  setup(props: Readonly<unknown>, context: SetupContext) {
    const popupLang: Ref<DigUiPopup | null> = ref(null)

    const countries: Country[] = [
      { lang: 'fr', flag: '🇫🇷', name: 'Français' },
      { lang: 'en', flag: '🇬🇧', name: 'English' },
    ]

    const close = () => {
      popupLang.value?.close()
    }

    const changeLocale = (lang: string) => {
      storeLocale.dispatch(StoreActionsLocale.CHANGE_LANG, lang)
      close()
    }

    return {
      countries,
      close,
      changeLocale,
      popupLang,
    }
  },
})
