import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-32955840")
const _hoisted_1 = ["disabled"]
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: "bottom-button",
    disabled: _ctx.disabled
  }, [
    _createElementVNode("span", null, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ])
  ], 8, _hoisted_1))
}