import { GetterTree, MutationTree, ActionTree, createStore, Store } from 'vuex'
import { utils } from '@/common/Utils'
import { loadLanguageAsync } from '@/locales'
import { InjectionKey } from 'vue'

export interface StoreStateLocale {
  lang: string
}

export enum StoreGettersLocale {
  LANG = 'LANG',
}

export enum StoreActionsLocale {
  CHANGE_LANG = 'CHANGE_LANG',
}

export enum StoreMutationsLocale {
  SET_LANG = 'SET_LANG',
}

const state: StoreStateLocale = {
  lang: 'fr',
}

const getters: GetterTree<StoreStateLocale, StoreStateLocale> = {
  [StoreGettersLocale.LANG]: (state) => state.lang,
}

const actions: ActionTree<StoreStateLocale, StoreStateLocale> = {
  [StoreActionsLocale.CHANGE_LANG]({ commit }, lang: string) {
    commit(StoreMutationsLocale.SET_LANG, lang)
  },
}

const mutations: MutationTree<StoreStateLocale> = {
  [StoreMutationsLocale.SET_LANG](state, lang: string) {
    state.lang = lang
    utils.locale = lang
    loadLanguageAsync(lang)
  },
}
export const key: InjectionKey<Store<StoreStateLocale>> = Symbol('')

export const storeLocale = createStore<StoreStateLocale>({
  state,
  mutations,
  getters,
  actions,
})
