
import { defineComponent, SetupContext, ref } from 'vue'

export default defineComponent({
  name: `BaseCollapsible`,
  components: {},
  props: {},
  emits: ['toggle'],
  setup(props: Readonly<any>, context: SetupContext) {
    const contentEl = ref()
    const toggled = ref(false)

    const toggle = () => {
      toggled.value = !toggled.value

      if (toggled.value) {
        expand(contentEl.value)
      } else {
        collapse(contentEl.value)
      }
    }

    const collapse = (element: HTMLElement) => {
      requestAnimationFrame(() => {
        element.style.height = 0 + 'px'
      })
    }

    const expand = (element: HTMLElement) => {
      requestAnimationFrame(() => {
        element.style.height = 'auto'
      })
    }

    return { toggle, toggled, contentEl }
  },
})
