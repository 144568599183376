
import { computed, defineComponent, SetupContext, StyleValue } from 'vue'

export default defineComponent({
  name: `BaseControlGrid`,
  components: {},
  props: {
    sideWidth: {
      type: Number,
      default: undefined,
    },
  },
  emits: [],
  setup(props: Readonly<any>, context: SetupContext) {
    const style = computed(() => {
      return {
        '--base-control-grid-side-width': `${props.sideWidth}px`,
      } as StyleValue
    })

    return { style }
  },
})
