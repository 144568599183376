import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-3c5df31c")
const _hoisted_1 = { class: "selected" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "locale-select",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.open()))
  }, [
    _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.selectedFlag), 1)
  ]))
}