
import { defineComponent, SetupContext } from 'vue'

export default defineComponent({
  name: `BaseBottomButton`,
  components: {},
  props: {
    disabled: {
      type: Boolean,
      required: false,
    },
  },
  emits: [],
  setup(
    props: Readonly<{
      disabled: boolean
    }>,
    context: SetupContext,
  ) {
    return {}
  },
})
