
import { defineComponent, SetupContext } from 'vue'

export default defineComponent({
  name: `BaseButton`,
  components: {},
  props: {
    marginTop: {
      type: Boolean,
      default: false,
    },
    marginBottom: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click'],
  setup(
    props: Readonly<{
      marginTop: boolean
      marginBottom: boolean
    }>,
    context: SetupContext,
  ) {
    return {}
  },
})
