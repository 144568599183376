export enum USER_ROLES {
  SUPERADMIN = 'superadmin',
  ADMIN = 'admin',
  DEV = 'dev',
  SALE = 'sale',
  USER = 'user',
  VISITOR = 'visitor',
}

export interface User {
  id?: number
  role?: USER_ROLES | null
  username?: string
  email?: string
  projectId?: number | null
  activated?: boolean
}
