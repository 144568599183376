import { utils } from '@/common/Utils'
import { storeContract, StoreGettersContract } from '@/store/contract'
import { storeStory, StoreGettersStory } from '@/store/story'
import { Contract } from './Contract'
import { Story } from './Story'

export interface Project {
  id?: number
  name?: string
  url?: string
  testurl?: string
  price?: number
  points?: number
  userIds?: number[]
  demandIds?: number[]
  availablePoints?: number
  version?: string
  active?: boolean
  intervention?: boolean
  interventionPoint?: number
  interventionDate?: string
  interventionName?: string
  interventionDescription?: string
}

export const DEFAULT_PROJECT: Project = {}

export const getAvailablePoints = (): // contracts: Contract[],
// stories: Story[],
number => {
  const contracts: Contract[] =
    storeContract.getters[StoreGettersContract.CONTRACTS]
  const stories: Story[] = storeStory.getters[StoreGettersStory.STORIES]

  const contractsPoints = contracts.reduce((points, contract) => {
    if (
      utils.UTCToMoment(contract.date?.replace(/\//g, '-')) >
      utils.nowToMoment().subtract(6, 'month')
    ) {
      return (contract.points || 0) + points
    }
    return points
  }, 0)

  const storiesPoints = stories.reduce((points, story) => {
    if (
      utils.UTCToMoment(story.date) > utils.nowToMoment().subtract(6, 'month')
    ) {
      return (story.points || 0) + points
    }
    return points
  }, 0)

  const oldContractsPoints = contracts.reduce((points, contract) => {
    if (
      utils.UTCToMoment(contract.date?.replace(/\//g, '-')) <
      utils.nowToMoment().subtract(6, 'month')
    ) {
      return (contract.points || 0) + points
    }
    return points
  }, 0)

  const oldStoriesPoints = stories.reduce((points, story) => {
    if (
      utils.UTCToMoment(story.date) < utils.nowToMoment().subtract(6, 'month')
    ) {
      return (story.points || 0) + points
    }
    return points
  }, 0)

  let oldPoints = oldContractsPoints - oldStoriesPoints
  if (oldPoints > 0) {
    oldPoints = 0
  }

  return contractsPoints - storiesPoints + oldPoints
}
