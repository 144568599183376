import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggle())),
    class: _normalizeClass({ toggled: _ctx.toggled })
  }, [
    _createElementVNode("span", null, [
      (!_ctx.toggled)
        ? _renderSlot(_ctx.$slots, "default", { key: 0 }, undefined, true)
        : _createCommentVNode("", true),
      (_ctx.toggled)
        ? _renderSlot(_ctx.$slots, "toggled", { key: 1 }, undefined, true)
        : _createCommentVNode("", true)
    ])
  ], 2))
}