import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/default-user.png'


_pushScopeId("data-v-0ec660af")
const _hoisted_1 = { class: "base-image-logo" }
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = ["alt"]
const _hoisted_4 = {
  key: 2,
  class: "image-control-buttons"
}
const _hoisted_5 = ["title"]
const _hoisted_6 = ["accept"]
const _hoisted_7 = ["title"]
const _hoisted_8 = ["title"]
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.src)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          src: _ctx.src,
          alt: _ctx.alt
        }, null, 8, _hoisted_2))
      : _createCommentVNode("", true),
    (!_ctx.src)
      ? (_openBlock(), _createElementBlock("img", {
          key: 1,
          src: _imports_0,
          alt: _ctx.alt
        }, null, 8, _hoisted_3))
      : _createCommentVNode("", true),
    (_ctx.canEdit)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("div", null, [
            (_ctx.src)
              ? (_openBlock(), _createElementBlock("dig-ui-icon", {
                  key: 0,
                  type: "delete",
                  class: "icon-button",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('delete'))),
                  title: _ctx.$t('delete')
                }, null, 8, _hoisted_5))
              : _createCommentVNode("", true),
            _createElementVNode("dig-input-file", {
              accept: _ctx.accept,
              onLoaded: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('load', $event)))
            }, [
              (_ctx.src)
                ? (_openBlock(), _createElementBlock("dig-ui-icon", {
                    key: 0,
                    type: "edit",
                    class: "icon-button",
                    title: _ctx.$t('editImage')
                  }, null, 8, _hoisted_7))
                : _createCommentVNode("", true),
              (!_ctx.src)
                ? (_openBlock(), _createElementBlock("dig-ui-icon", {
                    key: 1,
                    type: "add",
                    class: "icon-button",
                    title: _ctx.$t('addImage')
                  }, null, 8, _hoisted_8))
                : _createCommentVNode("", true)
            ], 40, _hoisted_6)
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}