
import { defineComponent, onMounted, computed, ref, Ref } from 'vue'
import {
  storeProject,
  StoreGettersProject,
  StoreActionsProject,
} from '@/store/project'
import { storeAuth, StoreGettersAuth, StoreActionsAuth } from '@/store/auth'
import { USER_ROLES } from '@/model/User'
import router, { goTo } from '@/router'
import { Project } from '@/model/Project'
import { DigUiView } from '@digithia/ui/nav'

export default defineComponent({
  name: 'Main',
  components: {},
  setup() {
    const version = '1.4.6'
    const digUiView: Ref<DigUiView | null> = ref(null)

    onMounted(() => {
      storeProject.dispatch(StoreActionsProject.GET_ALL)
      setTimeout(() => {
        digUiView.value?.refreshSideBar()
      }, 200)
    })
    const projects = computed(
      () => storeProject.getters[StoreGettersProject.PROJECTS] as Project[],
    )
    const lastProject = computed(
      () => storeProject.getters[StoreGettersProject.PROJECT],
    )
    const loggedUser = computed(() => storeAuth.getters[StoreGettersAuth.USER])
    const darkMode = computed(
      () => storeAuth.getters[StoreGettersAuth.DARK_MODE],
    )
    const mobile = computed(() => window.innerWidth < 600)

    const logout = () => {
      storeAuth.dispatch(StoreActionsAuth.LOGOUT)
      goTo('/login')
    }
    const goBack = () => {
      router.back()
    }
    const toggleDarkMode = () => {
      storeAuth.dispatch(StoreActionsAuth.TOGGLE_DARK_MODE)
    }
    const isSelected = (value: string) => {
      return router.currentRoute.value.fullPath.match(value)
    }

    return {
      goTo,
      projects,
      loggedUser,
      USER_ROLES,
      logout,
      version,
      goBack,
      mobile,
      toggleDarkMode,
      darkMode,
      isSelected,
      lastProject,
      digUiView,
    }
  },
})
