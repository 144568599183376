import { RouteRecordRaw, createRouter, createWebHistory } from 'vue-router'
import Main from '../views/Main.vue'
import NotFound from '../views/NotFound.vue'
import { StoreActionsAuth, storeAuth, StoreGettersAuth } from '@/store/auth'
import { __ } from '@/locales'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: Main,
    meta: {
      title: __(`Router.home`),
    },
    children: [
      {
        name: 'Account',
        path: 'account',
        component: () => import('../views/home/ViewMyAccount.vue'),
      },
      {
        name: 'Projects',
        path: 'projects',
        component: () => import('../views/home/ViewProjects.vue'),
      },
      {
        name: 'Project',
        path: 'projects/:id',
        component: () => import('../views/home/ViewProject.vue'),
      },
      {
        name: 'Requests',
        path: 'projects/:id/requests',
        component: () => import('../views/home/ViewDemands.vue'),
      },
      {
        name: 'Invoice',
        path: 'projects/:id/invoices/:name',
        component: () => import('../views/home/ViewInvoice.vue'),
      },
      {
        name: 'Contact',
        path: 'projects/:id/contacts',
        component: () => import('../views/home/ViewContacts.vue'),
      },
      {
        name: 'Story',
        path: 'stories/:id',
        component: () => import('../views/home/ViewStory.vue'),
      },
      {
        name: 'User',
        path: 'users/:id',
        component: () => import('../views/home/ViewUser.vue'),
      },
      {
        name: 'Accounts',
        path: 'accounts',
        component: () => import('../views/home/ViewUsers.vue'),
      },
      { path: '', redirect: 'projects' },
      {
        path: '/:catchAll(.*)',
        component: NotFound,
      },
    ],
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      title: __(`Router.login`),
    },
    component: () => import('../views/auth/ViewLogin.vue'),
  },
  {
    path: '/ask-reset-password',
    name: 'AskResetPassword',
    meta: {
      title: __(`Router.askResetPassword`),
    },
    component: () => import('../views/auth/ViewAskResetPassword.vue'),
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    meta: {
      title: __(`Router.resetPassword`),
    },
    component: () => import('../views/auth/ViewResetPassword.vue'),
  },
  {
    meta: {
      title: __(`Router.activateAccount`),
    },
    path: '/activate-account',
    name: 'ActivateAccount',
    component: () => import('../views/auth/ViewActivateAccount.vue'),
  },
  { path: '/*/', component: Main },
  // {
  //   path: '/:catchAll(.*)',
  //   component: NotFound,
  // },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach((to, from, next) => {
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title)
  if (nearestWithTitle) document.title = nearestWithTitle.meta.title as string

  if (to.query.token) {
    storeAuth.dispatch(StoreActionsAuth.SET_TOKEN, to.query.token)
  }

  if (
    to.name !== 'Login' &&
    to.name !== 'AskResetPassword' &&
    !storeAuth.getters[StoreGettersAuth.IS_LOGGED]
  ) {
    next({ name: 'Login' })
  } else {
    next()
  }
  // next()
})

export const goTo = (path: string) => {
  router.push(path)
}

export default router
