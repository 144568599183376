import { GetterTree, MutationTree, ActionTree, createStore, Store } from 'vuex'
import { dashboardApi } from '@/api/DashboardApi'
import { Contract } from '@/model/Contract'
import { InjectionKey } from 'vue'

export interface StoreStateContract {
  contract: Contract
  contracts: Contract[]
}

export enum StoreGettersContract {
  CONTRACT = 'CONTRACT',
  CONTRACTS = 'CONTRACTS',
}

export enum StoreActionsContract {
  GET_ALL = 'GET_ALL',
  ADD = 'ADD',
  UPDATE = 'UPDATE',
  GET = 'GET',
  DELETE = 'DELETE',
  SEARCH = 'SEARCH',
  DUPLICATE = 'DUPLICATE',
}

export enum StoreMutationsContract {
  SAVE_CONTRACTS = 'SAVE_CONTRACTS',
  PUSH_CONTRACT = 'PUSH_CONTRACT',
  SAVE_CONTRACT = 'SAVE_CONTRACT',
  UPDATE_CONTRACT = 'UPDATE_CONTRACT',
  SHIFT_CONTRACT = 'SHIFT_CONTRACT',
}

const state: StoreStateContract = {
  contract: {},
  contracts: [],
}

const getters: GetterTree<StoreStateContract, StoreStateContract> = {
  [StoreGettersContract.CONTRACT](state): Contract {
    return state.contract
  },
  [StoreGettersContract.CONTRACTS](state): Contract[] {
    return state.contracts
  },
}

const actions: ActionTree<StoreStateContract, StoreStateContract> = {
  async [StoreActionsContract.GET_ALL]({ commit }) {
    commit('SAVE_CONTRACTS', [])
    const json = await dashboardApi.get('contracts')
    if (json.success) {
      commit('SAVE_CONTRACTS', json.data)
    } else {
      commit('SAVE_CONTRACTS', [])
    }
    return json
  },
  async [StoreActionsContract.ADD]({ commit }, contract: Contract) {
    const json = await dashboardApi.post('contracts', contract)
    if (json.success) {
      commit('PUSH_CONTRACT', json.data)
    }
    return json
  },
  async [StoreActionsContract.UPDATE]({ commit }, { contract, id }) {
    const json = await dashboardApi.put(`contracts/${id}`, contract)
    if (json.success) {
      commit('UPDATE_CONTRACT', json.data)
    }
    return json
  },
  async [StoreActionsContract.GET]({ commit }, id: number) {
    const json = await dashboardApi.get(`contracts/${id}`)
    commit('SAVE_CONTRACT', json.data)
    return json
  },
  async [StoreActionsContract.DELETE]({ commit }, id: number) {
    const json = await dashboardApi.delete(`contracts/${id}`)
    if (json.success) {
      commit('SHIFT_CONTRACT', id)
    }
    return json
  },
  async [StoreActionsContract.SEARCH]({ commit }, searchdata: Contract) {
    commit('SAVE_CONTRACTS', [])
    const json = await dashboardApi.post('contracts/search', searchdata)
    commit('SAVE_CONTRACTS', json.data)
    return json
  },
  async [StoreActionsContract.DUPLICATE]({ commit }, id: number) {
    const json = await dashboardApi.post(`contracts/${id}/duplicate`)
    if (json.success) {
      commit(StoreMutationsContract.PUSH_CONTRACT, json.data)
    }
    return json
  },
}

const mutations: MutationTree<StoreStateContract> = {
  [StoreMutationsContract.SAVE_CONTRACTS](state, contracts: Contract[]) {
    state.contracts = contracts
  },
  [StoreMutationsContract.PUSH_CONTRACT](state, contract: Contract) {
    state.contracts.push(contract)
  },
  [StoreMutationsContract.SAVE_CONTRACT](state, contract: Contract) {
    if (contract) state.contracts.push(contract)
  },
  [StoreMutationsContract.UPDATE_CONTRACT](state, contract: Contract) {
    if (contract) {
      state.contracts = state.contracts.map((c) => {
        if (c.id === contract.id) {
          return contract
        }
        return c
      })
    }
  },
  [StoreMutationsContract.SHIFT_CONTRACT](state, id: number) {
    state.contracts = state.contracts.filter((s) => s.id !== id)
  },
}
export const key: InjectionKey<Store<StoreStateContract>> = Symbol('')

export const storeContract = createStore<StoreStateContract>({
  state,
  mutations,
  getters,
  actions,
})
