
import { defineComponent, SetupContext } from 'vue'

export default defineComponent({
  name: `BaseCtrlButtons`,
  components: {},
  props: {
    src: {
      type: String,
      required: false,
    },
    defaultSrc: {
      type: String,
      required: false,
    },
    accept: {
      type: String,
      default: '.png, .jpeg, .jpg',
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
    alt: {
      type: String,
      default: 'An image',
    },
  },
  emits: ['load', 'delete'],
  setup(props: Readonly<any>, context: SetupContext) {
    return {}
  },
})
