import { User } from './User'

export enum DEMAND_PRIORITIES {
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
  CRITIC = 'CRITIC',
}

export enum DEMAND_STATES {
  WAITING = 'WAITING',
  PROCESSING = 'PROCESSING',
  DONE = 'DONE',
  ABORT = 'ABORT',
}

export enum DEMAND_NATURES {
  IMPROVEMENT = 'IMPROVEMENT',
  FIX = 'FIX',
}

export interface Demand {
  id: number
  name?: string
  createdById: number
  createdBy?: User
  createDate: string
  editedById?: number
  editedBy?: User
  updateDate: string
  providerChecked: boolean
  providerCheckedBy?: number
  providerCheckedByName?: number
  providerCheckedAt?: string
  customerChecked: boolean
  customerCheckedBy?: number
  customerCheckedByName?: number
  customerCheckedAt?: string
  description: string
  points?: number
  priority: DEMAND_PRIORITIES
  state: DEMAND_STATES
  nature: DEMAND_NATURES
  version?: string
  browser?: string
  os?: string
  files?: string[]
}

export interface SearchDemand {
  free?: string
  providerChecked: boolean
  customerChecked: boolean
  priority: DEMAND_PRIORITIES[]
  state: DEMAND_STATES[]
  nature: DEMAND_NATURES[]
}

export const nextPriority = (
  priority: DEMAND_PRIORITIES,
): DEMAND_PRIORITIES => {
  if (priority === DEMAND_PRIORITIES.LOW) return DEMAND_PRIORITIES.MEDIUM
  if (priority === DEMAND_PRIORITIES.MEDIUM) return DEMAND_PRIORITIES.HIGH
  if (priority === DEMAND_PRIORITIES.HIGH) return DEMAND_PRIORITIES.CRITIC
  if (priority === DEMAND_PRIORITIES.CRITIC) return DEMAND_PRIORITIES.LOW
  return DEMAND_PRIORITIES.LOW
}
export const nextState = (state: DEMAND_STATES): DEMAND_STATES => {
  if (state === DEMAND_STATES.WAITING) return DEMAND_STATES.PROCESSING
  if (state === DEMAND_STATES.PROCESSING) return DEMAND_STATES.DONE
  if (state === DEMAND_STATES.DONE) return DEMAND_STATES.ABORT
  if (state === DEMAND_STATES.ABORT) return DEMAND_STATES.WAITING
  return DEMAND_STATES.WAITING
}
export const nextNature = (nature: DEMAND_NATURES): DEMAND_NATURES => {
  if (nature === DEMAND_NATURES.FIX) return DEMAND_NATURES.IMPROVEMENT
  if (nature === DEMAND_NATURES.IMPROVEMENT) return DEMAND_NATURES.FIX
  return DEMAND_NATURES.FIX
}

export const defaultDemand: Demand = {
  id: 0,
  createDate: '',
  updateDate: '',
  createdById: 0,
  providerChecked: false,
  customerChecked: false,
  description: '',
  priority: DEMAND_PRIORITIES.LOW,
  state: DEMAND_STATES.WAITING,
  nature: DEMAND_NATURES.IMPROVEMENT,
}

export const defaultSearchDemand: SearchDemand = {
  providerChecked: false,
  customerChecked: false,
  priority: [
    DEMAND_PRIORITIES.LOW,
    DEMAND_PRIORITIES.MEDIUM,
    DEMAND_PRIORITIES.HIGH,
    DEMAND_PRIORITIES.CRITIC,
  ],
  state: [
    DEMAND_STATES.ABORT,
    DEMAND_STATES.DONE,
    DEMAND_STATES.PROCESSING,
    DEMAND_STATES.WAITING,
  ],
  nature: [DEMAND_NATURES.IMPROVEMENT, DEMAND_NATURES.FIX],
}

export interface FilterDemand {
  name: keyof Demand
  active: boolean
  flow: 'normal' | 'reverse'
}

export const defaultFilterDemand: FilterDemand[] = [
  {
    name: 'createDate',
    active: true,
    flow: 'normal',
  },
  {
    name: 'updateDate',
    active: false,
    flow: 'normal',
  },
  {
    name: 'name',
    active: false,
    flow: 'normal',
  },
  {
    name: 'points',
    active: false,
    flow: 'normal',
  },
  {
    name: 'priority',
    active: false,
    flow: 'normal',
  },
]

export const searchDemand = (demands: Demand[], search: SearchDemand) => {
  return demands.filter((demand) => {
    return (
      (!search.free ||
        (demand.name &&
          demand.name.toLowerCase()?.match(search.free.toLowerCase())) ||
        (demand.version &&
          demand.version.toLowerCase()?.match(search.free.toLowerCase())) ||
        (demand.browser &&
          demand.browser.toLowerCase()?.match(search.free.toLowerCase())) ||
        (demand.os &&
          demand.os.toLowerCase()?.match(search.free.toLowerCase())) ||
        (demand.description &&
          demand.description
            .toLowerCase()
            ?.match(search.free.toLowerCase()))) &&
      (!demand.nature || search.nature.includes(demand.nature)) &&
      (!demand.priority || search.priority.includes(demand.priority)) &&
      (!demand.state || search.state.includes(demand.state))
    )
  })
}

export const getPriorityOrder = (priority: DEMAND_PRIORITIES) => {
  if (priority === DEMAND_PRIORITIES.CRITIC) return 1
  if (priority === DEMAND_PRIORITIES.HIGH) return 2
  if (priority === DEMAND_PRIORITIES.MEDIUM) return 3
  if (priority === DEMAND_PRIORITIES.LOW) return 4
  return 0
}

export const sortDemand = (demands: Demand[], filters: FilterDemand[]) => {
  return demands.sort((a, b) => {
    const createDate = filters.find((f) => f.name === 'createDate')
    if (createDate?.active) {
      if (createDate.flow === 'normal') {
        return a.createDate > b.createDate ? -1 : 1
      } else {
        return a.createDate > b.createDate ? 1 : -1
      }
    }
    const updateDate = filters.find((f) => f.name === 'updateDate')
    if (updateDate?.active) {
      if (updateDate?.flow === 'normal') {
        return (a.updateDate || a.createDate) > (b.updateDate || b.createDate)
          ? -1
          : 1
      } else {
        return (a.updateDate || a.createDate) > (b.updateDate || b.createDate)
          ? 1
          : -1
      }
    }
    const name = filters.find((f) => f.name === 'name')
    if (name?.active) {
      if (name?.flow === 'normal') {
        return a.name?.localeCompare(b.name || '') || 0
      } else {
        return b.name?.localeCompare(a.name || '') || 0
      }
    }
    const points = filters.find((f) => f.name === 'points')
    if (points?.active) {
      if (points?.flow === 'normal') {
        return (a.points || 0) - (b.points || 0)
      } else {
        return (b.points || 0) - (a.points || 0)
      }
    }
    const priority = filters.find((f) => f.name === 'priority')
    if (priority?.active) {
      if (priority?.flow === 'normal') {
        return getPriorityOrder(a.priority) - getPriorityOrder(b.priority)
      } else {
        return getPriorityOrder(b.priority) - getPriorityOrder(a.priority)
      }
    }

    return 0
  })
}
