import { InjectionKey } from 'vue'
import { ActionTree, createStore, GetterTree, MutationTree, Store } from 'vuex'
import { dashboardApi, ServerResponse } from '@/api/DashboardApi'
import { Story } from '@/model/Story'

export interface StoreStateStory {
  story: Story
  stories: Story[]
  report: string
}

export enum StoreGettersStory {
  STORY = 'STORY',
  STORIES = 'STORIES',
  REPORT = 'REPORT',
}

export enum StoreActionsStory {
  GET_ALL = 'GET_ALL',
  ADD = 'ADD',
  UPDATE = 'UPDATE',
  GET = 'GET',
  DELETE = 'DELETE',
  SEARCH = 'SEARCH',
  GET_REPORT = 'GET_REPORT',
  SET_REPORT = 'SET_REPORT',
  DELETE_REPORT = 'DELETE_REPORT',
  VALIDATE = 'VALIDATE',
  DUPLICATE = 'DUPLICATE',
}

export enum StoreMutationsStory {
  SET_STORIES = 'SET_STORIES',
  PUSH_STORY = 'PUSH_STORY',
  UPDATE_STORY = 'UPDATE_STORY',
  SET_STORY = 'SET_STORY',
  SHIFT_STORY = 'SHIFT_STORY',
  SET_REPORT = 'SET_REPORT',
  DELETE_REPORT = 'DELETE_REPORT',
  UPDATE_FIELD = 'UPDATE_FIELD',
}

const state: StoreStateStory = {
  story: {},
  stories: [],
  report: '',
}

const getters: GetterTree<StoreStateStory, StoreStateStory> = {
  [StoreGettersStory.STORY](state): Story {
    return state.story
  },
  [StoreGettersStory.STORIES](state): Story[] {
    return state.stories
  },
  [StoreGettersStory.REPORT](state): string {
    return state.report
  },
}

const actions: ActionTree<StoreStateStory, StoreStateStory> = {
  async [StoreActionsStory.GET_ALL]({ commit }): Promise<ServerResponse> {
    commit(StoreMutationsStory.SET_STORIES, [])
    const json = await dashboardApi.get(`stories`)
    if (json.success) {
      commit(StoreMutationsStory.SET_STORIES, json.data)
    } else {
      commit(StoreMutationsStory.SET_STORIES, [])
    }
    return json
  },
  async [StoreActionsStory.ADD](
    { commit },
    story: Story,
  ): Promise<ServerResponse> {
    const json = await dashboardApi.post(`stories`, story)
    if (json.success) {
      commit(StoreMutationsStory.PUSH_STORY, json.data)
    }
    return json
  },
  async [StoreActionsStory.UPDATE](
    { commit },
    { id, story },
  ): Promise<ServerResponse> {
    const json = await dashboardApi.put(`stories/${id}`, story)
    if (json.success) {
      commit(StoreMutationsStory.UPDATE_STORY, json.data)
    }
    return json
  },
  async [StoreActionsStory.GET](
    { commit },
    storyId: number,
  ): Promise<ServerResponse> {
    const json = await dashboardApi.get(`stories/${storyId}`)
    commit(StoreMutationsStory.SET_STORY, json.data)
    return json
  },
  async [StoreActionsStory.DELETE](
    { commit },
    storyId: number,
  ): Promise<ServerResponse> {
    const json = await dashboardApi.delete(`stories/${storyId}`)
    if (json.success) {
      commit(StoreMutationsStory.SHIFT_STORY, storyId)
    }
    return json
  },
  async [StoreActionsStory.SEARCH](
    { commit },
    searchdata: any,
  ): Promise<ServerResponse> {
    commit(StoreMutationsStory.SET_STORIES, [])
    const json = await dashboardApi.post(`stories/search`, searchdata)
    commit(StoreMutationsStory.SET_STORIES, json.data)
    return json
  },
  async [StoreActionsStory.GET_REPORT](
    { commit },
    storyId: number,
  ): Promise<ServerResponse> {
    const json = await dashboardApi.get(`stories/${storyId}/report`)
    commit(StoreMutationsStory.SET_REPORT, json.data)
    return json
  },
  async [StoreActionsStory.SET_REPORT](
    { commit },
    { storyId, base64, file },
  ): Promise<ServerResponse> {
    const json = await dashboardApi.post(`stories/${storyId}/report`, {
      base64,
      file,
    })
    if (json.success) {
      commit(StoreMutationsStory.SET_REPORT, json.data)
      commit(StoreMutationsStory.UPDATE_FIELD, {
        id: storyId,
        key: 'hasReport',
        value: true,
      })
    }
    return json
  },
  async [StoreActionsStory.DELETE_REPORT](
    { commit },
    storyId: number,
  ): Promise<ServerResponse> {
    const json = await dashboardApi.delete(`stories/${storyId}/report`)
    if (json.success) {
      commit(StoreMutationsStory.DELETE_REPORT, json.data)
      commit(StoreMutationsStory.UPDATE_FIELD, {
        id: storyId,
        key: 'hasReport',
        value: false,
      })
    }
    return json
  },
  async [StoreActionsStory.VALIDATE](
    { commit },
    { storyId },
  ): Promise<ServerResponse> {
    const json = await dashboardApi.post(`stories/${storyId}/validate`)
    if (json.success) {
      commit(StoreMutationsStory.UPDATE_FIELD, {
        id: storyId,
        key: 'validated',
        value: true,
      })
    }
    return json
  },
  async [StoreActionsStory.DUPLICATE]({ commit }, id: number) {
    const json = await dashboardApi.post(`stories/${id}/duplicate`)
    if (json.success) {
      commit(StoreMutationsStory.PUSH_STORY, json.data)
    }
    return json
  },
}

const mutations: MutationTree<StoreStateStory> = {
  [StoreMutationsStory.SET_STORIES](state, stories: Story[]): void {
    state.stories = stories
  },
  [StoreMutationsStory.PUSH_STORY](state, story: Story): void {
    state.stories.push(story)
  },
  [StoreMutationsStory.UPDATE_STORY](state, story: Story): void {
    if (story) {
      state.story = story
      state.stories = state.stories.map((c) => {
        if (c.id === story.id) {
          return story
        }
        return c
      })
    }
  },
  [StoreMutationsStory.UPDATE_FIELD](
    state,
    { id, key, value }: { id: number; key: keyof Story; value: any },
  ): void {
    if (state.story.id === id) {
      state.story[key] = value
    }
    state.stories = state.stories.map((s) => {
      if (s.id === id) {
        return { ...s, [key]: value }
      }
      return s
    })
  },
  [StoreMutationsStory.SHIFT_STORY](state, storyId: number): void {
    state.stories = state.stories.filter((s) => s.id !== storyId)
  },
  [StoreMutationsStory.SET_STORY](state, story: Story): void {
    state.story = story
  },
  [StoreMutationsStory.SET_REPORT](state, base64: string): void {
    state.report = base64
  },
  [StoreMutationsStory.DELETE_REPORT](state): void {
    state.report = ''
  },
}

export const key: InjectionKey<Store<StoreStateStory>> = Symbol('')

export const storeStory = createStore<StoreStateStory>({
  state,
  mutations,
  getters,
  actions,
})
