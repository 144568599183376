import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-464f45b2")
const _hoisted_1 = { class: "report-handler" }
const _hoisted_2 = { class: "padding" }
const _hoisted_3 = ["title"]
const _hoisted_4 = ["title"]
const _hoisted_5 = ["title"]
const _hoisted_6 = ["title"]
const _hoisted_7 = { class: "center title-2" }
const _hoisted_8 = { class: "center" }
const _hoisted_9 = ["data"]
const _hoisted_10 = {
  key: 1,
  class: "center title-3"
}
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_control_grid = _resolveComponent("base-control-grid")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_base_control_grid, { sideWidth: 160 }, {
      right: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          (_ctx.isSuperadmin && _ctx.report)
            ? (_openBlock(), _createElementBlock("dig-ui-icon", {
                key: 0,
                type: "delete",
                class: "icon-button",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('delete'))),
                title: _ctx.$t('delete')
              }, null, 8, _hoisted_3))
            : _createCommentVNode("", true),
          (_ctx.report)
            ? (_openBlock(), _createElementBlock("dig-ui-icon", {
                key: 1,
                type: "download",
                class: "icon-button",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('download'))),
                title: _ctx.$t('download')
              }, null, 8, _hoisted_4))
            : _createCommentVNode("", true),
          (_ctx.isSuperadmin)
            ? (_openBlock(), _createElementBlock("dig-input-file", {
                key: 2,
                onLoaded: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('load', $event))),
                accept: ".pdf"
              }, [
                (_ctx.isSuperadmin && _ctx.report)
                  ? (_openBlock(), _createElementBlock("dig-ui-icon", {
                      key: 0,
                      type: "upload",
                      class: "icon-button",
                      title: _ctx.$t('edit')
                    }, null, 8, _hoisted_5))
                  : _createCommentVNode("", true),
                (_ctx.isSuperadmin && !_ctx.report)
                  ? (_openBlock(), _createElementBlock("dig-ui-icon", {
                      key: 1,
                      type: "upload",
                      class: "icon-button",
                      title: _ctx.$t('add')
                    }, null, 8, _hoisted_6))
                  : _createCommentVNode("", true)
              ], 32))
            : _createCommentVNode("", true)
        ])
      ]),
      left: _withCtx(() => []),
      middle: _withCtx(() => [
        _createElementVNode("dig-ui-title", _hoisted_7, _toDisplayString(_ctx.$t('report')), 1)
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_8, [
      (_ctx.report && _ctx.report !== 'data:application/pdf;base64,')
        ? (_openBlock(), _createElementBlock("object", {
            key: 0,
            class: "pdf-viewer",
            data: _ctx.report,
            type: "application/pdf"
          }, null, 8, _hoisted_9))
        : _createCommentVNode("", true),
      (!_ctx.report || _ctx.report === 'data:application/pdf;base64,')
        ? (_openBlock(), _createElementBlock("dig-ui-title", _hoisted_10, _toDisplayString(_ctx.$t('noUpdateReport')), 1))
        : _createCommentVNode("", true)
    ])
  ]))
}