import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-77831200")
const _hoisted_1 = {
  ref: "popupLang",
  id: "popup-lang"
}
const _hoisted_2 = { class: "popup-content" }
const _hoisted_3 = { class: "title-3 center" }
const _hoisted_4 = { class: "langs" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "flag" }
const _hoisted_7 = { class: "name" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("dig-ui-popup", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("dig-ui-icon", {
        class: "close-popup",
        type: "close",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.close()))
      }),
      _createElementVNode("dig-ui-title", _hoisted_3, _toDisplayString(_ctx.$t('chooseLang')), 1),
      _createElementVNode("div", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.countries, (country) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "lang",
            key: country.lang,
            onClick: ($event: any) => (_ctx.changeLocale(country.lang))
          }, [
            _createElementVNode("div", _hoisted_6, _toDisplayString(country.flag), 1),
            _createElementVNode("div", _hoisted_7, _toDisplayString(country.name), 1)
          ], 8, _hoisted_5))
        }), 128))
      ])
    ])
  ], 512))
}