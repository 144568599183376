
import { defineComponent, SetupContext, ref, computed, onMounted } from 'vue'
import {
  storeLocale,
  StoreGettersLocale,
  StoreActionsLocale,
} from '@/store/locales'

export interface Country {
  lang: string
  flag: string
  name: string
}

export default defineComponent({
  name: `BaseLocaleSelect`,
  components: {},
  props: {},
  emits: ['validate', 'cancel'],
  setup(props: Readonly<any>, context: SetupContext) {
    let popupLang: any

    onMounted(() => {
      popupLang = document.querySelector('#popup-lang')
    })

    const countries: Country[] = [
      { lang: 'fr', flag: '🇫🇷', name: 'Français' },
      { lang: 'en', flag: '🇬🇧', name: 'English' },
    ]

    const selectedFlag = computed(() => {
      const flag = countries.find(
        (c) => c.lang === storeLocale.getters[StoreGettersLocale.LANG],
      )?.flag
      return flag
    })

    const open = () => {
      popupLang.trigger()
    }

    return {
      selectedFlag,
      close,
      open,
    }
  },
})
