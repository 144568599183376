<template>
  <div class="card">
    <slot></slot>
  </div>
</template>

<style scoped lang="scss">
.card {
  border: 1px solid var(--card-border-color);
  background-color: var(--card-background-color);
  border-radius: 6px;
  padding: 0px;
  margin: 0px;
}
</style>
