import {
  DEMAND_NATURES,
  DEMAND_PRIORITIES,
  DEMAND_STATES,
} from '@/model/Demand'
import { LocaleMessages, VueMessageType } from 'vue-i18n'

const messages: LocaleMessages<VueMessageType> = {
  fr: {
    hello: `Bonjour`,
    // Small texts
    login: `Se connecter`,
    logout: `Déconnexion`,
    add: `Ajouter`,
    new: `Nouveau`,
    edit: `Modifier`,
    expand: `Ouvrir`,
    collapse: `Fermer`,
    delete: `Supprimer`,
    sort: `Trier`,
    search: `Rechercher`,
    reinit: `Réinitialiser`,
    create: `Créer`,
    close: `Fermer`,
    download: `Télécharger`,
    upload: `Téléverser`,
    validate: `Valider`,
    cancel: `Annuler`,
    show: `Afficher`,
    hide: `Cacher`,
    downloading: `Téléchargement en cours`,
    uploading: `Téléversement en cours`,
    name: `Nom`,
    price: `Prix`,
    date: `Date`,
    noData: `Données indisponibles`,
    webapp: `Web App`,
    previous: `Précédent`,
    activated: `Activé`,
    desactivated: `Désactivé`,
    linkToProject: `Lier au projet`,
    unlinkToProject: `Retirer du projet`,
    previousShort: `Prec.`,
    nextShort: `Suiv.`,
    connection: `Connexion`,
    home: `Accueil`,
    myAccount: `Mon compte`,
    myAccountShort: `Compte`,
    logoutShort: `Déco`,
    contacts: `Contacts`,
    files: `Fichiers`,
    subscription: `Forfait`,
    available: `Disponible`,
    month: `mois`,
    months: `mois`,
    point: `Point`,
    points: `Points`,
    user: `Utilisateur`,
    users: `Utilisateurs`,
    invoice: `Facture`,
    project: `Projet`,
    projects: `Projets`,
    contract: `Crédit de maintenance`,
    contracts: `Crédits de maintenance`,
    story: `Intervention`,
    stories: `Interventions`,
    forgotMyPassword: `Mot de passe oublié ?`,
    availablePoints: `Disponibles`,
    report: `Rapport`,
    logo: `Logo`,
    chooseLang: `Choisissez votre langue`,
    askActivation: `Demande d'activation`,
    cannotConnectToServer: `Impossible de se connecter au serveur`,
    informCustomers: `Envoyer au client`,
    noStory: `Aucune intervention`,
    noContract: `Aucun crédit de maintenance`,
    noUser: `Aucun utilisateur`,
    noInvoice: `Aucune facture`,
    downloadReport: `Télécharger le rapport`,
    duplicate: `Dupliquer`,
    reset: `Réinitialisation`,
    // Plurals
    nbMonth: 'Aucun mois | {n} mois',
    nbPoints: 'Aucun point | {n} point | {n} points',
    nbUser: 'Aucun utilisateur | {n} utilisateur | {n} utilisateurs',
    nbInvoice: 'Aucune facture | {n} facture | {n} factures',
    nbProject: 'Aucun projet | {n} projet | {n} projets',
    nbStory: 'Aucune intervention | {n} intervention | {n} interventions',
    nbDemand: 'Aucune demande | {n} demande | {n} demandes',
    nbDemandState: 'Aucune demande | {n} demande | {n} demandes',
    nbContract:
      'Aucun crédit de maintenance | {n} crédit de maintenance | {n} crédits de maintenance',
    // Long texts
    noUpdateReport: `Aucun rapport d'intervention`,
    lastProject: `Dernier projet visité`,
    sureDeleteReport: `Êtes-vous sûr de vouloir supprimer le rapport de intervention ?`,
    onlineAccess: `Accéder à la WebApp`,
    onlineAccessTest: `Accéder à la WebApp Test`,
    goToRequests: `Consulter le tableau des demandes du projet`,
    chooseNewPassword: `Choisissez votre nouveau mot de passe`,
    askResetPassword: `Demander un nouveau mot de passe`,
    askResetPasswordMessage: `Pour obtenir un nouveau mot de passe, veuillez saisir votre nom d'utilisateur ou votre adresse mail et suivez les instructions de l'email que vous recevrez.`,
    choosePasswordActivateAccount: `Choisissez votre mot de passe pour activer votre compte`,
    activateAccount: `Activer le compte`,
    allProjects: `Tous les projets`,
    myProjects: `Mes applications`,
    editContract: `Modifier le crédit de maintenance`,
    editStory: `Modifier l'intervention`,
    editMyData: `Modifier mes infos`,
    editMyPassword: `Modifier mon mot de passe`,
    editUser: `Modifier l'utilisateur`,
    deleteMyAccount: `Supprimer mon compte`,
    oldPassword: `Ancien mot de passe`,
    newPassword: `Nouveau mot de passe`,
    iAmSur: `Je suis sûr`,
    deleteAccountPassphrase: `Supprimer le compte`,
    askIfSurDeleteOwnAccount: `Vous êtes bien sûr de vouloir supprimer ce compte ? Toutes les données concernant ce compte seront supprimées et cette action est irreversible. Pour pouvoir supprimer définitivement ce compte, taper la phrase suivante :`,
    editImage: `Modifier l'image`,
    addImage: `Ajouter une image`,
    leaveBlankToNotChange: `Laisser vide pour ne pas modifier`,
    invalidPassword: `Le mot de passe doit faire au moins 8 caractères.`,
    invalidForm: `Le formulaire est invalide`,
    linkUserToProject: `Lier l'utilisateur à un projet`,
    unlinkUserToProject: `Retirer l'utilisateur du projet`,
    selectProject: `Sélectionnez un projet`,
    addReport: `Ajouter un rapport`,
    allAccounts: `Tous les comptes`,
    maintenance: `Maintenance`,
    pageNotFound: `Page introuvable`,
    sendEmailToCustomers: `Envoyer un email aux clients`,
    listOfDemands: `Demandes de {project}`,
    askDeleteInvoice: `Êtes-vous sûr de vouloir supprimer cette facture ?`,
    Router: {
      home: `Dashboard - Accueil`,
      login: `Dashboard - Connexion`,
      askResetPassword: `Dashboard - Demande de nouveau mot de passe`,
      resetPassword: `Dashboard - Ré-initialisation du mot de passe`,
      activateAccount: `Dashboard - Activation du compte`,
    },
    Roles: {
      undefined: 'Non défini',
      superadmin: 'Superadmin',
      1: 'Superadmin',
      admin: 'Admin',
      2: 'Admin',
      developper: 'Développeur',
      3: 'Développeur',
      salesperson: 'Commercial',
      4: 'Commercial',
      user: 'Utilisateur',
      5: 'Utilisateur',
      visitor: 'Visiteur',
      6: 'Visiteur',
    },
    User: {
      new: `Nouvel utilisateur`,
      askDelete: `Êtes-vous sûr de vouloir supprimer l'utilisateur ?`,
      username: `Nom d'utilisateur`,
      usernameOrEmail: `Nom d'utilisateur ou email`,
      password: `Mot de passe`,
      email: `Email`,
      role: `Rôle`,
    },
    Contract: {
      askDelete: `Êtes-vous sûr de vouloir supprimer le contrat ?`,
      name: `Nom`,
      date: `Date`,
      price: `Prix`,
      points: `Points`,
      new: `Nouveau crédit de maintenance`,
    },
    Story: {
      askDelete: `Êtes-vous sûr de vouloir supprimer l'intervention ?`,
      name: `Nom`,
      date: `Date`,
      points: `Points`,
      description: `Description`,
      new: `Nouvelle intervention`,
    },
    Project: {
      name: `Nom`,
      points: `Points`,
      price: `Prix`,
      version: `Version`,
      active: `Maintenance`,
      url: `URL`,
      testurl: `URL de test`,
      new: `Nouveau projet`,
      edit: 'Modification du projet',
      askDelete: `Êtes-vous sûr de vouloir supprimer ce projet ? Toutes les informations le concernant seront supprimées définitivement.`,
      intervention: `Intervention automatique`,
      interventionPoint: `Points de l'intervention`,
      interventionDate: `Date de l'intervention`,
      interventionName: `Nom de l'intervention`,
      interventionDescription: `Description de l'intervention`,
    },
    Demand: {
      noPriority: `Aucune priorité`,
      noDescription: `Aucune description`,
      demandOf: `Demande de {name}`,
      editedBy: `Modifiée par {name} il y a {date}`,
      createdBy: `Créée par {name} il y a {date}`,
      createDate: `Date de création`,
      updateDate: `Date de modification`,
      editedAt: `Dernière modification`,
      checkedBy: `Approuvée par {name}`,
      state: `État`,
      State: {
        [DEMAND_STATES.ABORT]: `Annulée`,
        [DEMAND_STATES.DONE]: `Terminée`,
        [DEMAND_STATES.PROCESSING]: `En cours`,
        [DEMAND_STATES.WAITING]: `En attente`,
      },
      priority: `Priorité`,
      Priority: {
        [DEMAND_PRIORITIES.LOW]: `Basse`,
        [DEMAND_PRIORITIES.MEDIUM]: `Moyenne`,
        [DEMAND_PRIORITIES.HIGH]: `Haute`,
        [DEMAND_PRIORITIES.CRITIC]: `Critique`,
      },
      nature: `Nature`,
      Nature: {
        [DEMAND_NATURES.FIX]: `Correction`,
        [DEMAND_NATURES.IMPROVEMENT]: `Amélioration`,
      },
      description: `Description`,
      version: `Version`,
      browser: `Navigateur`,
      os: `Système`,
      points: `Points`,
      point: `Point`,
      nbPoints: `{n} point | {n} point | {n} points`,
      nbFiles: `{n} fichier | {n} fichier | {n} fichiers`,
      name: `Titre`,
      edit: `Modification de la demande`,
      askDelete: `Êtes-vous sûr de vouloir supprimer cette demande ?`,
      noVersion: `Aucune version précisée`,
      noBrowser: `Aucun navigateur`,
      noOS: `Aucun OS`,
      new: `Nouvelle demande`,
    },
  },
}

export default messages
