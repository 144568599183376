
import { defineComponent, SetupContext } from 'vue'

export default defineComponent({
  name: `BaseToggleButton`,
  components: {},
  props: {
    toggled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['toggled'],
  setup(
    props: Readonly<{
      toggled: boolean
    }>,
    context: SetupContext,
  ) {
    const toggle = () => {
      context.emit('toggled', !props.toggled)
    }

    return { toggle }
  },
})
