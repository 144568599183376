import { GetterTree, MutationTree, ActionTree, createStore, Store } from 'vuex'
import { dashboardApi, ServerResponse } from '@/api/DashboardApi'
import { DEFAULT_PROJECT, Project } from '@/model/Project'
import { User } from '@/model/User'
import { InjectionKey } from 'vue'

export interface StoreStateProject {
  project: Project
  image: string
  projects: Project[]
  users: User[]
  invoices: File[]
  invoice: string
}

export enum StoreGettersProject {
  PROJECT = 'PROJECT',
  PROJECTS = 'PROJECTS',
  USERS = 'USERS',
  INVOICE = 'INVOICE',
  INVOICES = 'INVOICES',
  IMAGE = 'IMAGE',
}

export enum StoreActionsProject {
  GET_ALL = 'GET_ALL',
  ADD = 'ADD',
  UPDATE = 'UPDATE',
  GET = 'GET',
  DELETE = 'DELETE',
  GET_USERS = 'GET_USERS',
  ADD_USER = 'ADD_USER',
  DELETE_USER = 'DELETE_USER',
  LINK_USER = 'LINK_USER',
  UNLINK_USER = 'UNLINK_USER',
  SET_IMAGE = 'SET_IMAGE',
  GET_IMAGE = 'GET_IMAGE',
  DELETE_IMAGE = 'DELETE_IMAGE',
  GET_INVOICES = 'GET_INVOICES',
  GET_INVOICE = 'GET_INVOICE',
  ADD_INVOICE = 'ADD_INVOICE',
  UPDATE_INVOICE = 'UPDATE_INVOICE',
  DELETE_INVOICE = 'DELETE_INVOICE',
}

export enum StoreMutationsProject {
  SET_PROJETS = 'SET_PROJETS',
  SET_PROJET = 'SET_PROJET',
  PUSH_PROJET = 'PUSH_PROJET',
  DELETE_PROJET = 'DELETE_PROJET',
  SET_USERS = 'SET_USERS',
  PUSH_USER = 'PUSH_USER',
  REMOVE_USER = 'REMOVE_USER',
  SET_INVOICES = 'SET_INVOICES',
  PUSH_INVOICE = 'PUSH_INVOICE',
  SHIFT_INVOICE = 'SHIFT_INVOICE',
  SET_INVOICE_FILE = 'SET_INVOICE_FILE',
  SET_IMAGE = 'SET_IMAGE',
}

const state: StoreStateProject = {
  project: {},
  projects: [],
  users: [],
  invoices: [],
  invoice: '',
  image: '',
}

const getters: GetterTree<StoreStateProject, StoreStateProject> = {
  [StoreGettersProject.PROJECT](state): Project {
    return state.project
  },
  [StoreGettersProject.PROJECTS](state): Project[] {
    return state.projects
  },
  [StoreGettersProject.USERS](state): User[] {
    return state.users
  },
  [StoreGettersProject.IMAGE](state): string {
    return state.image
  },
  [StoreGettersProject.INVOICE](state): string {
    return state.invoice
  },
  [StoreGettersProject.INVOICES](state): File[] {
    return state.invoices
  },
}

const actions: ActionTree<StoreStateProject, StoreStateProject> = {
  async [StoreActionsProject.GET_ALL]({ commit }): Promise<ServerResponse> {
    const json = await dashboardApi.get('projects')
    if (json.success) {
      commit(StoreMutationsProject.SET_PROJETS, json.data)
    } else {
      commit(StoreMutationsProject.SET_PROJETS, [])
    }
    return json
  },
  async [StoreActionsProject.ADD](
    { commit },
    project: Project,
  ): Promise<ServerResponse> {
    const json = await dashboardApi.post('projects', project)
    commit(StoreMutationsProject.PUSH_PROJET, json.data)
    return json
  },
  async [StoreActionsProject.UPDATE](
    { commit },
    { project, id },
  ): Promise<ServerResponse> {
    const json = await dashboardApi.put(`projects/${id}`, project)
    if (json.success) {
      commit(StoreMutationsProject.SET_PROJET, json.data)
    }
    return json
  },
  async [StoreActionsProject.GET](
    { commit },
    id: number,
  ): Promise<ServerResponse> {
    commit(StoreMutationsProject.SET_PROJET, DEFAULT_PROJECT)
    const json = await dashboardApi.get(`projects/${id}`)
    commit(StoreMutationsProject.SET_PROJET, json.data)
    return json
  },
  async [StoreActionsProject.DELETE](
    { commit },
    id: number,
  ): Promise<ServerResponse> {
    const json = await dashboardApi.delete(`projects/${id}`)
    if (json.success) {
      commit(StoreMutationsProject.DELETE_PROJET, id)
    }
    return json
  },
  async [StoreActionsProject.GET_USERS](
    { commit },
    id: number,
  ): Promise<ServerResponse> {
    commit(StoreMutationsProject.SET_USERS, [])
    const json = await dashboardApi.get(`projects/${id}/users`)
    commit(StoreMutationsProject.SET_USERS, json.data)
    return json
  },
  async [StoreActionsProject.ADD_USER]({ commit }, user: User) {
    commit(StoreMutationsProject.PUSH_USER, user)
  },
  async [StoreActionsProject.DELETE_USER]({ commit }, id: number) {
    commit(StoreMutationsProject.REMOVE_USER, id)
  },
  async [StoreActionsProject.LINK_USER](
    state,
    { userId, projectId },
  ): Promise<ServerResponse> {
    const json = await dashboardApi.post(
      `users/${userId}/linkToProject/${projectId}`,
      {},
    )
    return json
  },
  async [StoreActionsProject.UNLINK_USER](
    state,
    { userId, projectId },
  ): Promise<ServerResponse> {
    const json = await dashboardApi.post(
      `users/${userId}/unlinkToProject/${projectId}`,
      {},
    )
    return json
  },
  async [StoreActionsProject.SET_IMAGE](
    { commit },
    { base64, projectId, file },
  ): Promise<ServerResponse> {
    const body = { base64: base64, file: file }
    const json = await dashboardApi.post(`projects/${projectId}/image`, body)
    commit(StoreMutationsProject.SET_IMAGE, json.data)
    return json
  },
  async [StoreActionsProject.GET_IMAGE](
    { commit },
    projectId: number,
  ): Promise<ServerResponse> {
    commit(StoreMutationsProject.SET_IMAGE, '')
    const json = await dashboardApi.get(`projects/${projectId}/image`)
    commit(StoreMutationsProject.SET_IMAGE, json.data)
    return json
  },
  async [StoreActionsProject.DELETE_IMAGE](
    { commit },
    projectId: number,
  ): Promise<ServerResponse> {
    const json = await dashboardApi.delete(`projects/${projectId}/image`)
    if (json.success) {
      commit(StoreMutationsProject.SET_IMAGE, '')
    }
    return json
  },
  async [StoreActionsProject.GET_INVOICES](
    { commit },
    projectId: number,
  ): Promise<ServerResponse> {
    commit(StoreMutationsProject.SET_INVOICES, [])
    const json = await dashboardApi.get(`projects/${projectId}/invoices`)
    if (json.success) {
      commit(StoreMutationsProject.SET_INVOICES, json.data)
    } else {
      commit(StoreMutationsProject.SET_INVOICES, [])
    }
    return json
  },
  async [StoreActionsProject.GET_INVOICE](
    { commit },
    { projectId, name },
  ): Promise<ServerResponse> {
    const json = await dashboardApi.get(
      `projects/${projectId}/invoices/${name}`,
    )
    if (json.success) {
      commit(StoreMutationsProject.SET_INVOICE_FILE, json.data)
    }
    return json
  },
  async [StoreActionsProject.ADD_INVOICE](
    { commit },
    { projectId, base64, file },
  ): Promise<ServerResponse> {
    const json = await dashboardApi.post(`projects/${projectId}/invoices`, {
      base64,
      file,
    })
    if (json.success) {
      commit(StoreMutationsProject.PUSH_INVOICE, file)
    }
    return json
  },
  async [StoreActionsProject.DELETE_INVOICE](
    { commit },
    { projectId, name },
  ): Promise<ServerResponse> {
    const json = await dashboardApi.delete(
      `projects/${projectId}/invoices/${name}`,
    )
    if (json.success) {
      commit(StoreMutationsProject.SHIFT_INVOICE, name)
    }
    return json
  },
}

const mutations: MutationTree<StoreStateProject> = {
  [StoreMutationsProject.SET_PROJETS](state, projects: Project[]) {
    state.projects = projects
  },
  [StoreMutationsProject.SET_PROJET](state, project: Project) {
    state.project = project
  },
  [StoreMutationsProject.PUSH_PROJET](state, project: Project) {
    if (project) {
      state.projects.push(project)
    }
  },
  [StoreMutationsProject.DELETE_PROJET](state, id: number) {
    state.projects = state.projects?.filter((p) => p.id !== id)
  },
  [StoreMutationsProject.SET_USERS](state, users: User[]) {
    state.users = users
  },
  [StoreMutationsProject.PUSH_USER](state, user: User) {
    state.users.push(user)
  },
  [StoreMutationsProject.REMOVE_USER](state, id: number) {
    state.users = state.users?.filter((u) => u.id !== id)
  },
  [StoreMutationsProject.SET_INVOICES](state, invoices: File[]) {
    state.invoices = invoices
  },
  [StoreMutationsProject.PUSH_INVOICE](state, invoice: File) {
    state.invoices.push(invoice)
  },
  [StoreMutationsProject.SHIFT_INVOICE](state, name: string) {
    state.invoices = state.invoices?.filter((i) => i.name !== name)
  },
  [StoreMutationsProject.SET_INVOICE_FILE](state, base64: string) {
    state.invoice = base64
  },
  [StoreMutationsProject.SET_IMAGE](state, image: string) {
    state.image = image
  },
}

export const key: InjectionKey<Store<StoreStateProject>> = Symbol('')

export const storeProject = createStore<StoreStateProject>({
  state,
  mutations,
  getters,
  actions,
})
