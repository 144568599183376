import { __ } from '@/locales'
import { Toast } from '@/model/Toast'
import { StoreActionsAuth, storeAuth, StoreGettersAuth } from '@/store/auth'
import { StoreGettersLocale, storeLocale } from '@/store/locales'

export enum Methods {
  get = 'GET',
  post = 'POST',
  put = 'PUT',
  delete = 'DELETE',
}

export interface ServerResponse {
  code: number
  data?: unknown
  error?: string | any
  success?: boolean
  type?: string
  message?: string
  status?: number
}

class ApiHandler {
  baseUrl = `${process.env.VUE_APP_API_URL}api/`

  get token(): string {
    return storeAuth.getters[StoreGettersAuth.TOKEN]
  }

  get lang(): string {
    return storeLocale.getters[StoreGettersLocale.LANG]
  }

  headers = {
    'Access-Control-Allow-Headers': '*',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': '*',
    'Content-Type': 'application/json',
    Accept: 'application/json',
  }

  async get(path: string) {
    const request = {
      method: 'GET',
      headers: {
        ...this.headers,
        Authorization: this.token,
        'Accept-Language': this.lang,
      },
    }

    return fetch(`${this.baseUrl}${path}`, request)
      .then(async (response) => {
        const json = await response.json()
        this.logResponse(json, path, Methods.get)
        if (json.code === 'token_expired') {
          storeAuth.dispatch(StoreActionsAuth.LOGOUT)
        }
        return json
      })
      .catch((error) => this.handleError(error))
  }

  async post(path: string, body: any = {}) {
    const request = {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        ...this.headers,
        Authorization: this.token,
        'Accept-Language': this.lang,
      },
    }

    return fetch(`${this.baseUrl}${path}`, request)
      .then(async (response) => {
        const json = await response.json()
        this.logResponse(json, path, Methods.get)
        if (json.code === 'token_expired') {
          storeAuth.dispatch(StoreActionsAuth.LOGOUT)
        }
        return json
      })
      .catch((error) => this.handleError(error))
  }

  async put(path: string, body: any = {}) {
    const request = {
      method: 'PUT',
      body: JSON.stringify(body),
      headers: {
        ...this.headers,
        Authorization: this.token,
        'Accept-Language': this.lang,
      },
    }

    return fetch(`${this.baseUrl}${path}`, request)
      .then(async (response) => {
        const json = await response.json()
        this.logResponse(json, path, Methods.get)
        if (json.code === 'token_expired') {
          storeAuth.dispatch(StoreActionsAuth.LOGOUT)
        }
        return json
      })
      .catch((error) => this.handleError(error))
  }

  async delete(path: string, body: any = {}) {
    const request = {
      method: 'DELETE',
      body: JSON.stringify(body),
      headers: {
        ...this.headers,
        Authorization: this.token,
        'Accept-Language': this.lang,
      },
    }

    return fetch(`${this.baseUrl}${path}`, request)
      .then(async (response) => {
        const json = await response.json()
        this.logResponse(json, path, Methods.get)
        if (json.code === 'token_expired') {
          storeAuth.dispatch(StoreActionsAuth.LOGOUT)
        }
        return json
      })
      .catch((error) => this.handleError(error))
  }

  handleError(error: Error): Toast {
    console.error('Dashboard API error : ', error)
    if (error.message.match(`JSON.parse`))
      error.message = __(`cannotConnectToServer`)

    return { type: 'error', message: error.message }
  }

  handleResponse(response: Response) {
    return response.json()
  }

  logResponse(json: any, url: string, method: string) {
    if (process.env.VUE_APP_MODE === 'dev') {
      const response = { ...json }
      if (response && response.data && response.data.length > 200) {
        response.data = `${response.data}`.slice(0, 100)
      }
      console.debug(
        `Server call on ${method} ${url} and response with : `,
        response,
      )
    }
  }
}

export const dashboardApi = new ApiHandler()
