
import { defineComponent, onMounted, computed } from 'vue'
import { storeAuth, StoreGettersAuth } from './store/auth'
export default defineComponent({
  setup() {
    onMounted(() => {
      const preload = document.querySelector('#preload')
      preload?.remove()
    })

    const darkMode = computed(
      () => storeAuth.getters[StoreGettersAuth.DARK_MODE],
    )

    return { darkMode }
  },
})
