import { GetterTree, MutationTree, ActionTree, createStore, Store } from 'vuex'
import { dashboardApi, ServerResponse } from '@/api/DashboardApi'
import { User, USER_ROLES } from '@/model/User'
import { TokenData } from '@/model/TokenData'
import { InjectionKey } from 'vue'

export interface StoreStateAuth {
  token: string
  tokendata: TokenData
  darkMode: boolean
}

export enum StoreGettersAuth {
  TOKEN = 'TOKEN',
  TOKENDATA = 'TOKENDATA',
  USER = 'USER',
  USER_ID = 'USER_ID',
  ROLE = 'ROLE',
  USERNAME = 'USERNAME',
  IS_LOGGED = 'IS_LOGGED',
  DARK_MODE = 'DARK_MODE',
}

export enum StoreActionsAuth {
  LOGIN = 'LOGIN',
  ACTIVATE_ACCOUNT = 'ACTIVATE_ACCOUNT',
  CHANGE_OWN_PASSWORD = 'CHANGE_OWN_PASSWORD',
  DELETE = 'DELETE',
  RESET_PASSWORD = 'RESET_PASSWORD',
  ASK_RESET_PASSWORD = 'ASK_RESET_PASSWORD',
  LOGOUT = 'LOGOUT',
  SET_TOKEN = 'SET_TOKEN',
  TOGGLE_DARK_MODE = 'TOGGLE_DARK_MODE',
  UPDATE_ACCOUNT = 'UPDATE_ACCOUNT',
}

export enum StoreMutationsAuth {
  SET_TOKEN = 'SET_TOKEN',
  LOGOUT = 'LOGOUT',
  EXTRACT_TOKEN_DATA = 'EXTRACT_TOKEN_DATA',
  UPDATE_USER = 'UPDATE_USER',
  TOGGLE_DARK_MODE = 'TOGGLE_DARK_MODE',
}

const state: StoreStateAuth = {
  token: '',
  tokendata: {},
  darkMode: !!localStorage.getItem('darkMode'),
}

const getters: GetterTree<StoreStateAuth, StoreStateAuth> = {
  [StoreGettersAuth.TOKEN]: () => localStorage.getItem('token') || '',
  [StoreGettersAuth.TOKENDATA]: () =>
    JSON.parse(localStorage.getItem('tokendata') || '{}'),
  [StoreGettersAuth.USER]: (state, getters): User => {
    return getters[StoreGettersAuth.TOKENDATA].user || {}
  },
  [StoreGettersAuth.USER_ID]: (state, getters): number =>
    getters[StoreGettersAuth.USER]?.id,
  [StoreGettersAuth.ROLE]: (state, getters): USER_ROLES =>
    getters[StoreGettersAuth.USER]?.role,
  [StoreGettersAuth.USERNAME]: (state, getters): string =>
    getters[StoreGettersAuth.USER]?.username,
  [StoreGettersAuth.IS_LOGGED]: (state, getters): boolean => {
    return (
      new Date().getTime() <
      (getters[StoreGettersAuth.TOKENDATA].exp || 0) * 1000
    )
  },
  [StoreGettersAuth.DARK_MODE]: (state): boolean => {
    return state.darkMode
  },
}

const actions: ActionTree<StoreStateAuth, StoreStateAuth> = {
  async [StoreActionsAuth.LOGIN](
    { commit },
    { username, password },
  ): Promise<ServerResponse> {
    const json = await dashboardApi.post('users/login', { username, password })
    if (json.success) {
      commit('SET_TOKEN', json.data)
      commit('EXTRACT_TOKEN_DATA')
    }
    return json
  },
  async [StoreActionsAuth.ACTIVATE_ACCOUNT](
    { commit },
    { userId, password },
  ): Promise<ServerResponse> {
    const json = await dashboardApi.post(`users/${userId}/activate-account`, {
      password,
    })
    if (json.success) {
      commit('SET_TOKEN', json.data)
      commit('EXTRACT_TOKEN_DATA')
    }
    return json
  },
  async [StoreActionsAuth.UPDATE_ACCOUNT](
    state,
    { id, username, email, password },
  ): Promise<ServerResponse> {
    console.log(id, username, email, password)
    if (password) {
      const res = await dashboardApi.put(`users/${id}/password`, {
        username,
        password,
      })
      if (!res.success) return res
    }
    const json = await dashboardApi.put(`users/${id}`, {
      username,
      email,
    })
    return json
  },
  async [StoreActionsAuth.DELETE]({ getters }): Promise<ServerResponse> {
    const json = await dashboardApi.delete(
      `users/${getters[StoreGettersAuth.USER_ID]}`,
    )
    return json
  },
  async [StoreActionsAuth.RESET_PASSWORD](
    { commit, getters },
    password,
  ): Promise<ServerResponse> {
    const json = await dashboardApi.post(
      `users/${getters[StoreGettersAuth.USER_ID]}/reset-password`,
      {
        password,
      },
    )
    if (json.success) {
      commit('SET_TOKEN', json.data)
      commit('EXTRACT_TOKEN_DATA')
    }
    return json
  },
  async [StoreActionsAuth.ASK_RESET_PASSWORD](
    state,
    username,
  ): Promise<ServerResponse> {
    const json = await dashboardApi.post('users/ask-reset-password', {
      username,
    })
    return json
  },
  [StoreActionsAuth.LOGOUT]({ commit }): void {
    commit('LOGOUT')
  },
  [StoreActionsAuth.SET_TOKEN]({ commit }, token): void {
    commit('SET_TOKEN', token)
    commit('EXTRACT_TOKEN_DATA')
  },
  [StoreActionsAuth.TOGGLE_DARK_MODE]({ commit }): void {
    commit('TOGGLE_DARK_MODE')
  },
}

const mutations: MutationTree<StoreStateAuth> = {
  [StoreMutationsAuth.SET_TOKEN](state, token) {
    state.token = token
    localStorage.setItem('token', token)
  },
  [StoreMutationsAuth.LOGOUT](state) {
    state.token = ''
    localStorage.setItem('token', '')
    state.tokendata = {}
    localStorage.setItem('tokendata', JSON.stringify({}))
  },
  [StoreMutationsAuth.EXTRACT_TOKEN_DATA](state) {
    const token = state.token
    if (!token) {
      localStorage.setItem('tokendata', JSON.stringify({}))
      state.tokendata = {}
      return
    }
    const base64Url = token.split('.')[1]
    if (!base64Url) {
      localStorage.setItem('tokendata', JSON.stringify({}))
      state.tokendata = {}
      return
    }
    const base64 = base64Url.replace('-', '+').replace('_', '/')
    const tokendata = JSON.parse(window.atob(base64) || '')
    state.tokendata = tokendata
    localStorage.setItem('tokendata', JSON.stringify(state.tokendata || {}))
  },
  [StoreMutationsAuth.UPDATE_USER](state, user: User) {
    state.tokendata.user = user
    localStorage.setItem('tokendata', JSON.stringify(state.tokendata || {}))
  },
  [StoreMutationsAuth.TOGGLE_DARK_MODE](state) {
    state.darkMode = !state.darkMode
    localStorage.setItem(
      'darkMode',
      localStorage.getItem('darkMode') ? '' : 'true',
    )
  },
}

export const key: InjectionKey<Store<StoreStateAuth>> = Symbol('')

export const storeAuth = createStore<StoreStateAuth>({
  state,
  mutations,
  getters,
  actions,
})
